input[type=text]:disabled, input[type=number]:disabled, input[type=email]:disabled {
  background: #f6f6f6;
}
.set-avatar{
  display: none;
 
}
.MuiAutocomplete-tagSizeMedium{
  height: unset !important;
}
/* .MuiAutocomplete-endAdornment button{
  display: none;
} */
.view-avatar:hover + .set-avatar {
  display: block;
}
.set-avatar:hover {
  display: block;
}
#SearchStyle .MuiOutlinedInput-notchedOutline {
  top: 0
}
#SearchStyle .MuiOutlinedInput-notchedOutline legend {
  display: none
}
#buttonTable button{
  padding: 0px 5px;
}
.display-inline{
display:inline;
padding: 2px 10px;
/* width: 28px;
height: 28px; */
}
.display-inline span{
  font-size: 20px;
}
#buttonTable2 button{
  padding: 4px 10px;
  display: inline;
  /* flex: none !important; */
}

#buttonCircle button{
  /* border-radius: 50%; */
  padding: 1px;
}
.align-middle{
  /* display: none; */
}
.full-shadow{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
#imageIcon i{
  font-size: 20px;
  
}
#fieldset fieldset{
  border: none;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.w-108{
	width: 28rem;
} 

.h-10{height:4.5rem}
.max-w-1xl{
	max-width: 28rem;
}

.h-\[400px\]{
	height: 400px;
}
@media (max-width: 720px){
    .container{max-width:640px}
    .w-108{width: 20rem} 
    .max-w-1xl{max-width: 20rem}
    .text-2xl{font-size: 1rem}
    .h-\[400px\]{
      height: 300px;
    }
}

#formOne input[type=number]::-webkit-inner-spin-button, 
#formOne input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} */

.whatsappChatBox.open {
  animation-name: _bounceIn;
  animation-duration: 800ms;
  transition-timing-function: ease-out;
  animation-fill-mode: both;
}

.whatsappChatBox.close {
  animation-name: _bounceOut;
  animation-duration: 1000ms;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
}


@keyframes _bounceIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: center center;
  }
/* 
  10% {
    opacity: 0.1; 
    transform-origin: bottom right;
  }
  50% {
    opacity: 0.5; 
    transform-origin: bottom right;
  } */

  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: center center;
  }
}

@keyframes _bounceOut {
  0% {
    opacity: 1;
    height: scale(1, 1);
    transform-origin: bottom right;
  }
  /* 50% {
    transform: scale(1.03, 1.03);
    transform-origin: bottom right;
  } */
  100% {
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: bottom right;
  }
}


.scrollbar
{  
	overflow-y: scroll; 
	overflow-x: hidden; 
}

.scrollbar-style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #b4b4b4;
	background-color: #fff;
}

.scrollbar-style-4::-webkit-scrollbar
{
	width: 4px;
	background-color: #fff;
}

.scrollbar-style-4::-webkit-scrollbar-thumb
{
	background-color: #858585;
	border: 1px solid #b4b4b4;
}

/* .MuiInputLabel-root .Mu
 */
 .muiinputlabel-root.mui-focused, .MuiInputLabel-root.Mui-focused{
  color: #0288d1 !important;
 }
 .MuiInput-underline:after, .MuiInput-underline:focus,   .MuiInput-underline:hover:not(.Mui-disabled):before {
  /* left: 0;
  right: 0;
  bottom: 0;
  content: ""; */
  /* position: absolute; */
  transform: none;
  transition: none !important;
  border-bottom: 2px solid #0288d1 !important;
  /* pointer-events: none; */
}
#removed .MuiInput-underline:before,#removed .MuiInput-underline:hover:not(.Mui-disabled):before,#removed .MuiInput-underline:after,#removed .MuiInput-underline:focus{
  border: none !important;
}
#messages_container input{
  height: 12px;
}
#rotate-45 span{
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}



.example {
  margin: 12px;
}

/* Force update ant style */
.ant-input {
  padding: 16px 12px 4px 11px;
}

.ant-select .ant-select-selector {
  padding: 16px 10px 4px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 10px 4px 11px;
  height: 48px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
}
.float-label {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
}

.label-float {
  top: 6px;
  font-size: 10px;
}

.navbar-bg-color{
  background-color: #0000;
}
.navbar-wrapper button,
.navbar-wrapper button:hover, 
.navbar-wrapper button:focus , 
.navbar-wrapper button:active{
  background-color: unset;
}

.profile-nav-top{
  display: none;
}
.profile-nav-top button>span{
  display: block
}

.profile-nav{
  display: block;
}

 .logout{
  display: none;
}
.add-more-files{
  font-size: 20px;
  padding-top:6px;
}
.dropzone-area-inner svg{
  font-size: 150px;
  width: 100%;
}
.do-not-auth{
  display: none
  }
@media (max-width: 1024px){
  .do-not-auth{
    
    text-align: center;
  display: block
  }
  .dropzone-area-inner{
    padding-top: 0px;
  }
  .dropzone-area-inner svg{
    font-size: 50px;
  }
  .dropzone-area-inner p{
    padding: 2px 0px;
  }
  .add-more-files{
    font-size: 14px;
  }
.navbar-bg-color.dark {
  background-color: #000000d1;
    background-image: linear-gradient(#001aff7f, #000000d1);
}

.profile-nav-top button>span{
  display: none
}
 .logout{
  display: block;
}
.logout .span{
  position: relative;
  top: 2px;
  /* font-size: 20px;
  position: relative;
  top: 5px;
  padding: 0px 4px; */
}
.profile-nav{
  display: none;
}

.profile-nav-top{
  display: block;
}
}
  



/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
