.App {
  /* font-family: sans-serif; */
}

/* ul {
  margin: 0;
}

span {
  font-size: 2rem;
  color: #777;
} */
.app-full{
    width: 100%;
    position: fixed;
    top:0
}
.dropzone-full {
  width: 100%;
  position: fixed;
  top:0;
  background-color: #0000007e;
  color: #ebebeb;
  text-align: center;
  align-items: center;
  padding-top: 150px;
  
}
  .dropzone {
  /* text-align: center; */
  /* padding: 20px ; */
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;

  /* margin: 80px 20px; */
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}
